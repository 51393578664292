import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import bgImage from "@images/resources/footer-bg-shape-1.png";
import footerLogo from "@images/logo-PB-footer.png";

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <footer className="site-footer">
      <div
        className="site-footer-bg"
        style={{ backgroundImage: `url(${bgImage})` }}
      ></div>
      <Container>
        <Row>
          <Col xl={12}>
            <div className="footer-upper text-center">
              <div className="footer-logo">
                <img src={footerLogo} width="240" alt="Footer Logo" />
              </div>
              <ul className="footer-widget__social li a" >
              <li >
                  <a href="https://www.linkedin.com/company/prompt-breeders">
                    <i 
                      className="fab fa-linkedin" 
                      
                      ></i>
                  </a>
                </li>
              </ul>
              <div>
                <p className="text-center">
                  <a href="mailto:contact@promptbreeders.com.com?subject=Demande d'informations">contact@promptbreeders.com</a>
                </p>
              </div>
              
              
            </div>
          </Col>
        </Row>
      </Container>
      <div className="site-footer__bottom">
        <Container className="text-center">
          <p>
            © Copyright {year} <a href="https://promptbreeders.com">PromptBreeders</a>, a <a href="https://teemz.com">Teemz</a> brand.
          </p>
        </Container>
      </div>
    </footer>
  );
};

/* suppression temporaire des icones réseaux sociaux
<ul className="footer-widget__social">
<li>
  <a href="#">
    <i className="fab fa-twitter"></i>
  </a>
</li>
<li>
  <a href="#" className="color-1">
    <i className="fab fa-facebook"></i>
  </a>
</li>
<li>
  <a href="#" className="color-2">
    <i className="fab fa-pinterest-p"></i>
  </a>
</li>
<li>
  <a href="#" className="color-3">
    <i className="fab fa-instagram"></i>
  </a>
</li>
</ul>
*/



export default Footer;
