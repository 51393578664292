import React from "react";
import { Link } from "gatsby";
import { Link as ScrollLink } from "react-scroll";
const NavLinks = () => {
  const handleDropdownStatus = (e) => {
    let clickedItem = e.currentTarget.parentNode;
    clickedItem.querySelector(".dropdown-list").classList.toggle("show");
  };

  return (
    <ul className="main-nav__navigation-box one-page-scroll-menu">
      <li>
        <Link to="/">
          Home
        </Link>
      </li>
      <li>
        <Link
          to="/conseil"
          >
          Consulting
        </Link>
      </li>
      <li>
        <Link
          to="/solutions"
          >
          Solutions
        </Link>
      </li>
      <li>
        <Link
          to="/formation"
          >
          Trainings
        </Link>
      </li>


      <li>
        <Link
          to="/blog"
          >
          Blog
        </Link>
      </li>    

      <li>
        <Link
          to="/contactpage"
          >
          Contact
        </Link>
      </li>    

      
      <li className="footer-widget__social li a">
      <a href="https://www.linkedin.com/company/prompt-breeders">
                    <i 
                      className="fab fa-linkedin"
                       
                      
                      ></i>
                  </a>


      </li>    
      
  
    </ul>
  );
};

/* Liens pour le blog temporairement retirés

      <li className="dropdown">
        <ScrollLink
          to="blog"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >
          News
        </ScrollLink>
        <button className="dropdown-btn" onClick={handleDropdownStatus}>
          <i className="fa fa-angle-right"></i>
        </button>
        <ul className="dropdown-list">
          <li>
            <Link to="/blog">
              News
            </Link>
          </li>
          <li>
            <Link to="/blog-details">
              News Details
            </Link>
          </li>
        </ul>
      </li>
*/



export default NavLinks;
